import { CreateControllerFn, ControllerParams } from '@wix/yoshi-flow-editor';
import { GroupsWidgetController } from './Controller/GroupsWidgetController';

const createController: CreateControllerFn = async (
  controllerContext: ControllerParams,
) => {
  // For more info about controller structure,
  // check the docs: https://bo.wix.com/pages/yoshi/docs/editor-flow/structure-api/component#controller
  return new GroupsWidgetController(controllerContext);
};

export default createController;
